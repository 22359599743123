import React from 'react';
import Link from 'next/link';

function Footer() {
  return (
    <footer className='footer-home'>
      <div
        style={{
          display: 'flex'
        }}
      >
        <ul style={{ listStyleType: 'none' }}>
          <li>
            <Link href='/'>
              <strong>Products</strong>
            </Link>
          </li>
          <li>
            <Link href='/'>
              <a href='/'>Corporate Card</a>
            </Link>
          </li>
          <li>
            <Link href='/reimbursement'>
              <a href='/reimbursement'>Reimbursement</a>
            </Link>
          </li>
          <style jsx>
            {`
              .text-dark {
                text-decoration: none;
              }
            `}
          </style>
        </ul>

        <ul style={{ listStyleType: 'none' }}>
          <li>
            <Link href='/'>
              <strong>Support</strong>
            </Link>
          </li>
          <li>
            <Link href='/support/privacy-policy'>
              <a href='/support/privacy-policy'>Privacy Policy</a>
            </Link>
          </li>
          <li>
            <Link href='/support/terms-conditions'>
              <a href='/support/terms-conditions'>Terms & Conditions</a>
            </Link>
          </li>
          <style jsx>
            {`
              .text-dark {
                text-decoration: none;
              }
            `}
          </style>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
